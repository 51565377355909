import { IReviewPublic } from "./review"

const addressListConst = [
	"eastMetro",
	"westMetro",
	"northTama",
	"southTama",
	"westTama",
	"kanagawa",
	"saitama",
	"chiba",
	"tochigi",
	"ibaraki",
	"hokkaido",
	"aomori",
	"iwate",
	"miyagi",
	"akita",
	"yamagata",
	"fukushima",
	"niigata",
	"toyama",
	"ishikawa",
	"fukui",
	"yamanashi",
	"nagano",
	"gifu",
	"shizuoka",
	"aichi",
	"mie",
	"shiga",
	"kyoto",
	"osaka",
	"hyogo",
	"nara",
	"wakayama",
	"tottori",
	"shimane",
	"okayama",
	"hiroshima",
	"yamaguchi",
	"tokushima",
	"kagawa",
	"ehime",
	"kochi",
	"fukuoka",
	"saga",
	"nagasaki",
	"kumamoto",
	"oita",
	"miyazaki",
	"kagoshima",
	"okinawa"] as const
export const addressList = addressListConst as unknown as string[]
export type IAddress = typeof addressListConst[number]

export interface IHospitalNew {
	status: 'waitReview' | 'approved' | 'rejected'
	payed?: number // 0: 未課金
	name: string //会社名病院名
	furigana: string
	address: IAddress
	addressDetails: string //完全な住所
	tel: string
	personName?: string //ご担当者名 無いのもあり得るから
	personNameFurigana?: string // ご担当者名の 無いのもあり得るから
	type: string // 動物病院
	stripeCustomer?: string | 'invoice6'
	stripePaymentStatus?: null | 'success' | 'pending' | 'failing' | 'canceled' | 'trial' | 'trialReady' // trial: 支払い方法未設定 / trialReady: 支払い方法設定済み
	trialWillEnd?: number // unix
	/* and other attributes */
}
export interface IHospitalAPIReturn {
	hospitalData: IHospitalDB
	reviews: IReviewPublic[]
	interviews: IInterview[]
}
const jobStyleConst = ['fulltime', 'parttime', 'temporary'] as const
export const jobStyleList = jobStyleConst as unknown as string[]
type IJobStyle = typeof jobStyleConst[number]

const jobTypeConst = ['veterinarian', 'nurse', 'trimmer', 'trainer'] as const
export const jobTypeList = jobTypeConst as unknown as string[]
type IJobType = typeof jobTypeConst[number]

export interface IApplyNew {
	// 広告掲載でストップ
	status: 'draft' | 'waitReview' | 'public' | 'finished'
	labelTag: string //病院側にだけ公開される分類タグ
	hospital: string
	hospitalName?: string //法人名と病院名が違う場合に記入
	attachedFiles?: string // JSON, 0-4枚, キャプションは自由(-20文字)
	// インタビューがある病院を上にしてconcat
	logo?: string // https://cdn
	description: string // 仕事内容自由記入
	prPoint: string // PRポイント
	// URLは入力禁止
	requiredQualification: string //応募資格
	style: IJobStyle //雇用形態(正社員・アルバイト・業務委託)
	time: string //勤務時間 自由
	treatment: string // 待遇・福利厚生
	tags: string // comma split and started with ",": キーワード@Slack
	type: IJobType // Slack
	salary: string // 時給・日給・月給・年収別で！！いっぱいあるけど！: JSONで行きます
	location: string // 完全な住所
	address: string // 最寄り駅
	payUnlocked?: 'locked' | 'unlocked'
	area: string
}
export interface IAPIReturnWithReviewInterview extends IAPIReturnWithReview {
	interview: IInterview[]
}
export interface IInterview {
	id: string
	// 任意: 社員インタビューとして 自由記入
	name: string // 名前
	status: 'accepted' | 'waitReview' | 'rejected' | 'draft'
	hospital: string
	jobtype: IJobType // 職種
	photo?: string // 1枚
	option1: string // commma split value: 仕事のやりがい・スキルアップ度・労働環境・院内の雰囲気・給与水準・教育制度(5段階)
	option2: string // この病院を入社した理由を教えてください
	option3: string // お仕事のやりがいは何ですか
	option4: string // 貴院の強みは何ですか
	option5: string // 学生のうちにやっておけばいいと思うことは何ですか
}
export interface IApply extends IApplyNew {
	id: string
	createdAtUnix: number
	prp: number
}
interface IApplySalaryValue {
	max?: number
	min: number
	definitive: boolean //「〜」をつけるか
}
export interface IApplySalary {
	monthly?: IApplySalaryValue
	annual?: IApplySalaryValue
	daily?: IApplySalaryValue
	hourly?: IApplySalaryValue
	note?: string
}
export interface IApplyPhoto {
	url: string
	caption: string
}
export interface IHospitalDB extends IHospitalNew {
	id: string
	num: number
	email: string
}
const bussinessTypeConst = ['hospital', 'salon', 'shop', 'sitter', 'cafe', 'maker', 'other'] as const
export const bussinessTypeList = bussinessTypeConst as unknown as string[]

export type IBussinessType = typeof bussinessTypeConst[number]

export interface IAPIReturn extends IApply {
	attachedFiles: any
	option1: any
	tags: any
	salary: any
}
export interface IAPIReturnFinal extends IAPIReturn {
	hospitalData: IHospitalDB
	attachedFiles: IApplyPhoto[]
	option1: number[]
	tags: string[]
	salary: IApplySalary
	faved?: boolean
}
export interface IAPIReturnWithReview extends IAPIReturnFinal {
	reviews: IReviewPublic[]
	reviewPt: number
	descriptionSanitized: string
}
/* 業種複数選択OK

動物病院
ペットサロン
ペットショップ
ペットシッター
動物カフェ
メーカー
その他
*/
export const tagList = [
	[
		'新卒歓迎',
		'経験者歓迎',
		'業界未経験者OK',
		'ブランクある方OK',
		'1年目で診察可能'
	], [
		'1次医療',
		'1.5 2次医療（高度医療）',
		'エキゾ診察あり',
		'漢方に強い',
		'スタッフ10名以上',
		'スタッフ10名未満',
		'CTあり',
		'MRIあり',
		'腹腔鏡あり',
		'グループ病院'
	], [
		'一般外科',
		'一般内科',
		'予防医療',
		'整形外科',
		'軟部外科',
		'麻酔科',
		'皮膚科',
		'眼科',
		'歯科・口腔科',
		'脳・神経科',
		'呼吸器科',
		'循環器科',
		'消化器科',
		'腎泌尿器科',
		'腫瘍・がん科',
		'内分泌科',
		'血液内科・免疫科',
		'感染症科',
		'救命救急',
		'再生医療',
		'行動診療科・行動学',
		'画像診断',
		'東洋医学・中医学',
		'リハビリ',
		'しつけ・パピークラス',
		'エキゾチックアニマル',
	], [
		'パート・アルバイト可',
		'正社員採用',
		'副業可',
		'週休完全2日制',
		'土日祝休み可',
		'長期休暇あり',
		'有休取得率80%',
	], [
		'午前のみ勤務可',
		'午後のみ勤務可',
		'時短勤務可',
		'残業ほぼなし'
	], [
		'初任給30万円以上',
		'初任給25万円以上',
		'開業志向歓迎',
	], [
		'社会保険完備',
		'産休・育休あり',
		'住宅補助あり',
		'セミナー等補助あり',
		'院内セミナーあり',
		'資格取得支援あり'
	]]