
import { ref, defineComponent } from 'vue'
import * as api from '../../utils/api'
import * as storage from '../../utils/storage'
import Menu from '../../components/menu/Hospital.vue'
import { UploadFileInfo, useDialog } from 'naive-ui'
import { tags, jobStyle, workTypes } from '../../utils/const'
import { IInterview } from '../../../interfaces/hospital'
type Rating = 'rating1' | 'rating2' | 'rating3' | 'rating4' | 'rating5' | 'rating6'

export default defineComponent({
	components: {
		Menu,
	},
	data() {
		const formRef2 = ref(null)
		const modelRef2 = ref({
			rating1: 3,
			rating2: 3,
			rating3: 3,
			rating4: 3,
			rating5: 3,
			rating6: 3,
			option2: '',
			option3: '',
			option4: '',
			option5: '',
			name: '',
			jobtype: 'veterinarian',
		})
		const vStandard = (lengthLimit: number, required: boolean, value: string) => {
			if (!value && required) return new Error('必須の項目です')
			return value.length <= lengthLimit || new Error('20文字以内で入力してください')
		}
		const vsa = (lengthLimit: number, required: boolean) => {
			return [
				{
					required,
					validator(r: any, v: string) {
						return vStandard(lengthLimit, required, v)
					},
					trigger: ['input', 'blur'],
				},
			]
		}
		const showModalRef = ref(false)
		const previewImageUrlRef = ref('')
		function handlePreview(file: any) {
			const { url } = file
			previewImageUrlRef.value = url
			showModalRef.value = true
		}
		const dialog = useDialog()
		const errorDialog = (e: any, onPositiveClick: Function) =>
			dialog.error({
				title: 'Error',
				content: e.message || '不明なエラー',
				closable: false,
				positiveText: 'OK',
				onPositiveClick: () => onPositiveClick(),
			})
		return {
			formRef2,
			model2: modelRef2,
			loading: false,
			monthly: false,
			annual: true,
			daily: false,
			hourly: false,
			success: false,
			addressLoading: false,
			admin: false,
			errorDialog,
			headers: {},
			actionUrl: '',
			err: '',
			editId: '',
			hospitalId: '',
			tags: tags(),
			js: jobStyle(),
			workTypes,
			rules2: {
				name: vsa(140, true),
				option2: vsa(1000, true),
				option3: vsa(1000, true),
				option4: vsa(1000, true),
				option5: vsa(1000, true),
			},
			handlePreview,
			showModal: showModalRef,
			previewImageUrl: previewImageUrlRef,
			photo: ref([]) as any,
		}
	},
	mounted: function () {
		document.title = 'インタビューの新規作成 - Vets Index'
		api.refresh()
		const token = storage.getKeyItem('credential', 'accessToken')
		const role = storage.getKeyItem('credential', 'role')
		const userData = storage.getKeyItem('credential', 'userData')
		this.hospitalId = userData.id
		if(role === 'admin') this.admin = true
		this.headers = {
			Authorization: `Bearer ${token}`,
			Role: role
		}
		this.actionUrl = api.host + '/v1/upload'
		const fileNames = location.pathname.match(/\/([a-zA-Z0-9-]+)$/)
		if (!fileNames) return false
		const fileName = fileNames[1]
		const isCopy = !!location.pathname.match('interview_copy')
		if (fileName !== 'interview_new' && !isCopy) this.editId = fileName
		if (fileName !== 'interview_new') this.init(fileName)
	},
	methods: {
		init: async function (id: string) {
			try {
				const data = (await api.get(`/v1/hospital/interview/${id}`, false, true)) as IInterview
				this.model2.jobtype = data.jobtype || ''
				this.model2.name = data.name || ''
				this.model2.option2 = data.option2 || ''
				this.model2.option3 = data.option3 || ''
				this.model2.option4 = data.option4 || ''
				this.model2.option5 = data.option5 || ''
				const rates = data.option1 as unknown as number[]
				this.model2.rating1 = rates[0]
				this.model2.rating2 = rates[1]
				this.model2.rating3 = rates[2]
				this.model2.rating4 = rates[3]
				this.model2.rating5 = rates[4]
				this.model2.rating6 = rates[5]
				if (data.photo) {
					this.photo = {
						id: data.photo,
						name: data.photo,
						status: 'finished',
						percentage: 1,
						url: data.photo,
					}
				}
			} catch (e: any) {
				this.errorDialog(e, () => true)
				console.error(e)
			}
		},
		post: async function (status: string) {
			const ip = this.photo[0] ? this.photo[0].url : undefined
			let inte: any = {
					status,
					photo: ip,
					name: this.model2.name,
					option1: [this.model2.rating1 || -1, this.model2.rating2 || -1, this.model2.rating3 || -1, this.model2.rating4 || -1, this.model2.rating5 || -1, this.model2.rating6 || -1],
					option2: this.model2.option2,
					option3: this.model2.option3,
					option4: this.model2.option4,
					option5: this.model2.option5,
					jobtype: this.model2.jobtype,
				}
			const param = {
				...inte,
				admin: this.admin
			}
			try {
				const data = await api.post(this.editId ? `/v1/hospital/interview/edit/${this.editId}` : `/v1/hospital/interview/new`, param, true)
				this.$router.push('/hospital/interview_list')
			} catch (e: any) {
				this.errorDialog(e, () => true)
				console.error(e)
			}
		},
		notReviewed: function (target: Rating) {
			this.model2[target] = -1
		},
		ipFinish: function (e: any) {
			const json = JSON.parse(e.event.target.response)
			if (json.success) {
				const intervieweePhoto = this.photo
				intervieweePhoto.push({
					id: json.path,
					name: json.path,
					status: 'finished',
					url: json.cdn,
					caption: '',
				})
				this.photo = intervieweePhoto
			} else {
				this.errorDialog(json, () => true)
			}
		},
        lookCheck: function () {
            window.open(`/h/${this.hospitalId}#interview_${this.editId}`, '_blank')
        }
	},
})
