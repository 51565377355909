export const prefectures = [
    {
        type: 'group',
        label: '首都圏',
        key: 'metro',
        children: [
            {
                label: '23区東部',
                value: 'eastMetro'
            },
            {
                label: '23区西部',
                value: 'westMetro'
            },
            {
                label: '多摩北部',
                value: 'northTama'
            },
            {
                label: '多摩南部',
                value: 'southTama'
            },
            {
                label: '多摩西部',
                value: 'westTama'
            },
            {
                label: '神奈川県',
                value: 'kanagawa'
            },
            {
                label: '埼玉県',
                value: 'saitama'
            },
            {
                label: '千葉県',
                value: 'chiba'
            },
            {
                label: '栃木県',
                value: 'tochigi'
            },
            {
                label: '茨城県',
                value: 'ibaraki'
            }
        ]
    },
    {
        type: 'group',
        label: '北海道地方',
        key: 'hokkaidoRegion',
        children: [
            {
                label: '北海道',
                value: 'hokkaido'
            }
        ]
    },
    {
        type: 'group',
        label: '東北地方',
        key: 'tohoku',
        children: [
            {
                label: '青森県',
                value: 'aomori'
            },
            {
                label: '岩手県',
                value: 'iwate'
            },
            {
                label: '宮城県',
                value: 'miyagi'
            },
            {
                label: '秋田県',
                value: 'akita'
            },
            {
                label: '山形県',
                value: 'yamagata'
            },
            {
                label: '福島県',
                value: 'fukushima'
            }
        ]
    },
    {
        type: 'group',
        label: '甲信越',
        key: 'koshinetsu',
        children: [
            {
                label: '新潟県',
                value: 'niigata'
            },
            {
                label: '富山県',
                value: 'toyama'
            },
            {
                label: '石川県',
                value: 'ishikawa'
            },
            {
                label: '福井県',
                value: 'fukui'
            },
            {
                label: '山梨県',
                value: 'yamanashi'
            },
            {
                label: '長野県',
                value: 'nagano'
            }
        ]
    },
    {
        type: 'group',
        label: '東海',
        key: 'tokai',
        children: [
            {
                label: '岐阜県',
                value: 'gifu'
            },
            {
                label: '静岡県',
                value: 'shizuoka'
            },
            {
                label: '愛知県',
                value: 'aichi'
            },
            {
                label: '三重県',
                value: 'mie'
            }
        ]
    },
    {
        type: 'group',
        label: '近畿地方',
        key: 'kinki',
        children: [
            {
                label: '滋賀県',
                value: 'shiga'
            },
            {
                label: '京都府',
                value: 'kyoto'
            },
            {
                label: '大阪府',
                value: 'osaka'
            },
            {
                label: '兵庫県',
                value: 'hyogo'
            },
            {
                label: '奈良県',
                value: 'nara'
            },
            {
                label: '和歌山県',
                value: 'wakayama'
            }
        ]
    },
    {
        type: 'group',
        label: '中国地方',
        key: 'chugoku',
        children: [
            {
                label: '鳥取県',
                value: 'tottori'
            },
            {
                label: '島根県',
                value: 'shimane'
            },
            {
                label: '岡山県',
                value: 'okayama'
            },
            {
                label: '広島県',
                value: 'hiroshima'
            },
            {
                label: '山口県',
                value: 'yamaguchi'
            }
        ]
    },
    {
        type: 'group',
        label: '四国地方',
        key: 'shikoku',
        children: [
            {
                label: '徳島県',
                value: 'tokushima'
            },
            {
                label: '香川県',
                value: 'kagawa'
            },
            {
                label: '愛媛県',
                value: 'ehime'
            },
            {
                label: '高知県',
                value: 'kochi'
            }
        ]
    },
    {
        type: 'group',
        label: '九州・沖縄地方',
        key: 'kyushu',
        children: [
            {
                label: '福岡県',
                value: 'fukuoka'
            },
            {
                label: '佐賀県',
                value: 'saga'
            },
            {
                label: '長崎県',
                value: 'nagasaki'
            },
            {
                label: '熊本県',
                value: 'kumamoto'
            },
            {
                label: '大分県',
                value: 'oita'
            },
            {
                label: '宮崎県',
                value: 'miyazaki'
            },
            {
                label: '鹿児島県',
                value: 'kagoshima'
            },
            {
                label: '沖縄県',
                value: 'okinawa'
            }
        ]
    }
]
export const westTama = ['奥多摩町', '青梅市', '日の出町', '檜原村', 'あきる野市', '羽村市', '瑞穂町', '福生市']
export const northTama = ['武蔵村山市', '立川市', '昭島市', '国立市', '国分寺市', '東大和市', '東村山市', '小平市', '清瀬市', '東久留米市', '西東京市', '小金井市', '武蔵野市', '三鷹市', '府中市', '調布市', '狛江市']
export const southTama = ['八王子市', '日野市', '多摩市', '稲城市', '町田市']
export const westMetro = ['北区', '板橋区', '練馬区', '中野区', '杉並区', '豊島区', '文京区', '新宿区', '千代田区', '中央区', '港区', '渋谷区', '世田谷区', '目黒区', '品川区', '大田区']
export const eastMetro = ['足立区', '荒川区', '台東区', '墨田区', '江東区', '江戸川区', '葛飾区']