export const usePremium = false

import { prefectures as pref} from '../../interfaces/area'
export const prefectures = pref
export const workTypes = [
  {
    label: '獣医師',
    value: 'veterinarian'
  },
  {
    label: '動物看護師',
    value: 'nurse'
  },
  {
    label: 'トリマー',
    value: 'trimmer'
  },
  {
    label: 'トレーナー',
    value: 'trainer'
  }
]

export const fillSheetProp = ['name', 'furigana', 'univName', 'faculty', 'grade', 'gender', 'birthday', 'tel', 'postcode', 'address', 'type', 'prMyself', 'history', 'qualification'] as const
export const fillSheetPropList = fillSheetProp as unknown as string[]
type FillSheetProp = typeof fillSheetProp[number]
export const fillSheetPropLine = ['lineId', 'lineName', 'email', 'name', 'furigana', 'univName', 'faculty', 'grade', 'gender', 'birthday', 'tel', 'postcode', 'address', 'type', 'prMyself', 'history', 'qualification'] as const

export type FillSheet = {
  [key in FillSheetProp]: string
}
export interface PrefArrayFillSheet extends FillSheet {
  preferPref: string[]
}
export interface PrefArrayFillSheetLine extends PrefArrayFillSheet {
  lineId: string
  lineName: string
  email: string
}
import { bussinessTypeList, jobStyleList, tagList } from '../../interfaces/hospital'
export const bussinessTypeJa = ['動物病院', 'ペットサロン', 'ペットショップ', 'シッター', '動物カフェ', 'メーカー', 'その他']
export const bussnessType = () => {
  const label = []
  for (let i = 0; i < bussinessTypeList.length; i++) label.push({ label: bussinessTypeJa[i], value: bussinessTypeList[i] })
  return label
}
const jobStyleJa = ['正社員', 'アルバイト', '業務委託']
export const jobStyle = () => {
  const label = []
  for (let i = 0; i < jobStyleList.length; i++) label.push({ label: jobStyleJa[i], value: jobStyleList[i] })
  return label
}
export const tagCats = ['スキルの要件', '獣医療的特徴', '力を入れている獣医療分野', '働き方', '勤務時間', '将来設計', '福利厚生']
export const tags = () => {
  const label = []
  for (const cat of tagList) {
    const labelChildren = []
    for (let i = 0; i < cat.length; i++) {
      labelChildren.push({ label: cat[i], value: cat[i] })
    }
    label.push(labelChildren)
  }
  return label
}